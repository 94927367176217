/*-===============================
    COUNTER AREA
===============================-*/
.counter-area {
  .section-heading {
    .section__title {
      br {
        @media #{$tab_device} {
          display: none;
        }
        @media #{$large_mobile} {
          display: none;
        }
        @media #{$small_mobile} {
          display: none;
        }
      }
    }
  }
}
.counter-wrap {
  margin-top: 30px;
}

/*========= counter-item =========*/
.counter-item {
  margin-bottom: 30px;
  @include transition(0.3s);
  .counter-content {
    span {
      display: block;
    }
    .counter__icon {
      width: 70px;
      height: 70px;
      line-height: 70px;
      margin-left: auto;
      margin-right: auto;
      background-color: $alter-color-7;
      @include border-radius(65% 30% 30% 65%/55% 35% 50% 40%);
      @include box-shadow(0 0 40px rgba(82, 85, 90, 0.2));
      @include transition(0.3s);
      font-size: $display-8;
      color: $white;
    }
    .counter__numb {
      font-size: $display-7;
      color: $theme-color;
      font-weight: $font-weight-semi-bold;
      margin-top: 33px;
      margin-bottom: 30px;
    }
    .counter__title {
      font-size: $display-14;
      color: $theme-color-4;
    }
  }
  &:hover {
    .counter-content {
      .counter__icon {
        @include border-radius(50%);
      }
    }
  }
}
/*-===============================
    counter-item-2
===============================-*/
.counter-item-2 {
  background-color: $white;
  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.05));
  padding-top: 40px;
  padding-bottom: 40px;
  @include border-radius(4px);
  position: relative;
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    width: 50px;
    height: 4px;
    @include transform(translateX(-50%));
    @include border-radius(0 0 4px 4px);
    background-color: $alter-color-8;
    @include transition(0.3s);
    opacity: 0.5;
  }
  .counter-content {
    .counter__icon {
      background-color: rgba(26, 151, 240, 0.1);
      width: 65px;
      height: 65px;
      line-height: 65px;
      @include border-radius(50%);
      color: $alter-color-8;
      @include box-shadow(0 0 0 0);
    }
    .counter__numb {
      margin-bottom: 20px;
      font-size: $display-9;
    }
    .counter__title {
      font-size: $default-font;
    }
  }
  &:hover {
    .counter-content {
      .counter__icon {
        background-color: $alter-color-8;
        color: $white;
      }
    }
    &:after {
      opacity: 1;
    }
  }
}
/*-===============================
    counter-item-3
===============================-*/
.counter-item-3 {
  .counter-content {
    background-color: $white;
    width: 235px;
    height: 235px;
    padding-top: 80px;
    text-align: center;
    position: relative;
    @include border-radius(50%);
    @include box-shadow(0 0 40px rgba(14, 16, 48, 0.05));
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
    @media #{$laptop_m_four} {
      padding-top: 70px;
      width: 210px;
      height: 210px;
    }
    @media #{$tab_device} {
      padding-top: 65px;
    }
    @media #{$large_mobile} {
      padding-top: 65px;
    }
    @media #{$small_mobile} {
      padding-top: 65px;
    }
    @media #{$small_mobile_one} {
      margin-left: auto;
      margin-right: auto;
    }
    &:after {
      position: absolute;
      content: '';
      top: 12px;
      left: 12px;
      width: 90%;
      height: 90%;
      border: 3px dotted rgba(92, 72, 224, 0.2);
      @include border-radius(50%);
      z-index: -1;
    }
    .counter__icon {
      position: absolute;
      top: 70px;
      left: 40px;
      font-size: $display-4;
      color: rgba(127, 136, 151, 0.1);
      background-color: transparent;
      @include border-radius(0);
      @include box-shadow(0 0 0 0);
      width: auto;
      height: auto;
      line-height: inherit;

    }
    .counter__numb{
      font-size: $display-6;
      font-weight: $font-weight-medium;
      color: $alter-color;
      margin-top: 0;
      margin-bottom: 0;
      sup {
        left: -15px;
      }
    }
    .counter__title {
      font-size: $default-font-2;
      font-weight: $font-weight-medium;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
  &:nth-child(2) {
    .counter-item {
      .counter-content {
        .counter__numb {
          color: $alter-color-2;
        }
        &:after {
          border-color: rgba(75, 230, 184, 0.2);
        }
      }
    }
  }
  &:nth-child(3) {
    .counter-item {
      .counter-content {
        .counter__numb {
          color: $alter-color-3;
        }
        &:after {
          border-color: rgba(247, 140, 1, 0.2);
        }
      }
    }
  }
  &:nth-child(4) {
    .counter-item {
      .counter-content {
        .counter__numb {
          color: $alter-color-4;
        }
        &:after {
          border-color: rgba(197, 0, 238, 0.2);
        }
      }
    }
  }
}
/*-===============================
     counter-item-4
===============================-*/
.counter-item-4 {
  @media #{$tab_device} {
    text-align: center;
  }
  @media #{$large_mobile} {
    text-align: center;
  }
  @media #{$small_mobile} {
    text-align: center;
  }
  .counter-content {
    .counter__icon {
      width: 60px;
      line-height: 60px;
      height: 60px;
      margin-left: 0;
      text-align: center;
      @include border-radius(4px);
      background-color: $theme-color;
      position: relative;
      @media #{$tab_device} {
       margin-left: auto;
      }
      @media #{$large_mobile} {
       margin-left: auto;
      }
      @media #{$small_mobile} {
       margin-left: auto;
      }
      &:after {
        position: absolute;
        content: '';
        bottom: -9px;
        left: 50%;
        @include transform(translateX(-50%));
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 9px solid $theme-color;
        @include transition(0.3s);
      }
    }
    .counter__numb {
      margin-top: 40px;
    }
  }
  &:hover {
    .counter-content {
      .counter__icon {
        @include border-radius(4px);
        background-color: $theme-color-2;
        &:after {
          border-top-color: $theme-color-2;
        }
      }
    }
  }
}
/*-===============================
     counter-item-5
===============================-*/
.counter-item-5 {
  .counter-content {
    .counter__icon {
      background-color: $theme-color-2;
      line-height: 70px;
      @include border-radius(50%);
      @include box-shadow(0 0 0 0);
      position: relative;
      &:after {
        position: absolute;
        content: '';
        bottom: -6px;
        left: 50%;
        @include transform(translateX(-50%));
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid $theme-color-2;
      }
    }
    .counter__numb {
      color: $theme-color-2;
      margin-bottom: 25px;
    }
    .counter__title {
      font-size: $display-15;
    }
  }
  &:hover {
    .counter-content {
      .counter__icon {
        @include border-radius(50%);
      }
    }
  }
}
/*-===============================
    FUNFACT AREA
===============================-*/
.funfact-area {
  position: relative;
  z-index: 1;
  .funfact-img-box {
    position: relative;
    margin-top: 20px;
    margin-bottom: 30px;
    img {
      width: 100%;
      border: 10px solid $white;
      @include box-shadow(0 0 40px rgba(14,16,48,.05));
      @include border-radius(4px);
    }
    .video-play-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      color: $theme-color;
      font-size: $display-10;
      width: 100px;
      height: 100px;
      line-height: 100px;
      @include border-radius(4px 0 4px 0);
      background-color: $white;
      @include transition(0.3s);
      &:hover {
        color: $theme-color-2;
      }
    }
  }
  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 456px;
    z-index: -1;
    background-color: $theme-color-6;
    @media #{$tab_device} {
      height: 698px;
    }
    @media #{$large_mobile} {
      height: 698px;
    }
    @media #{$small_mobile} {
      height: 698px;
    }
    @media #{$large_mobile_three} {
      height: 1117px;
    }
  }
}
/*-===============================
    FUNFACT AREA 2
===============================-*/
.funfact-area2 {
  .section-heading {
    .section__title {
      font-weight: $font-weight-regular;
      font-size: $display-8;
      color: $theme-color-4;
    }
  }
  .counter-wrap {
    .col-lg-3 {
      &:nth-child(2),
      &:nth-child(3){
        .counter-item {
          margin-top: 90px;
          @media #{$tab_device} {
            margin-top: 0;
          }
          @media #{$large_mobile} {
            margin-top: 0;
          }
          @media #{$small_mobile} {
            margin-top: 0;
          }
        }
      }
      &:nth-child(2) {
        .counter-item {
          .counter-content {
            .counter__icon {
              background-color: $theme-color-5;
              &:after {
                border-top-color: $theme-color-5;
              }
            }
            .counter__numb {
              color: $theme-color-5;
            }
          }
        }
      }
      &:nth-child(3) {
        .counter-item {
          .counter-content {
            .counter__icon {
              background-color: $alter-color-2;
              &:after {
                border-top-color: $alter-color-2;
              }
            }
            .counter__numb {
              color: $alter-color-2;
            }
          }
        }
      }
      &:nth-child(4) {
        .counter-item {
          .counter-content {
            .counter__icon {
              background-color: $alter-color-7;
              &:after {
                border-top-color: $alter-color-7;
              }
            }
            .counter__numb {
              color: $alter-color-7;
            }
          }
        }
      }
    }
  }
  &:after,
  &:before{
    position: absolute;
    content: '';
    width: 200px;
    height: 200px;
    top: 50px;
    left: 50px;
    background-image: url("../images/dots.png");
    background-size: cover;
    background-position: center;
    @include border-radius(50%);
    opacity: 0.2;
  }
  &:before {
    left: auto;
    top: auto;
    bottom: 50px;
    right: 50px;
    background-color: transparent;
  }
}

.get-started-text {
  margin-top: 50px;
  .getstart__desc {
    .theme-btn {
      background-color: transparent;
      color: $theme-color;
      line-height: 45px;
      font-size: $default-font-3;
      &:hover {
        background-color: $theme-color-2;
        color: $white;
      }
    }
  }
}
/*-===============================
    FUNFACT AREA 3
===============================-*/
.funfact-area3 {
  .counter-item-3 {
    .counter-content {
      .counter__numb {
        span {
          display: inline-block;
        }
      }
    }
  }
}
/*-===============================
    FUNFACT AREA 4
===============================-*/
.funfact-area4 {
  .counter-wrap {
    margin-top: 60px;
    .counter-item {
      .counter-content {
        .counter__icon {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
/*-===============================
    FUNFACT AREA 5
===============================-*/
.funfact-area5 {
  .counter-wrap {
    margin-top: 45px;
    .counter-item {
      .counter-content {
        .counter__icon {
          background-color: rgba(108, 92, 231, 0.1);
          color: $theme-color-2;
          @include box-shadow(0 0 0 0);
        }
        .counter__numb {
          span {
            display: inline-block;
          }
          .counter__percent {
            font-size: $display-11;
            top: -13px;
            right: 10px;
          }
        }
      }
    }
  }
}





