/*-===============================
   TIMELINE AREA
===============================-*/
.timeline-area {
  .timeline-wrap {
    margin-top: 30px;
  }
}

.zigzag-timeline-list {
  @media #{$large_mobile_three} {
    overflow: hidden;
  }
  li {
    border-top: 3px solid;
    border-image: linear-gradient(to right, #8f42ec 0%, #ba42ec 100%);
    border-image-slice: 1;
    border-width: 3px;
    margin: 0;
    padding: 40px 40px 38px 40px;
    counter-increment: section;
    position: relative;
    line-height: 28px;
    &:before {
      content: counter(section);
      position: absolute;
      @include border-radius(50%);
      height: 40px;
      width: 40px;
      text-align: center;
      line-height: 40px;
      background: #8f42ec;
      background: -moz-linear-gradient(left,  #8f42ec 0%, #ba42ec 100%);
      background: -webkit-linear-gradient(left,  #8f42ec 0%,#ba42ec 100%);
      background: linear-gradient(to right,  #8f42ec 0%,#ba42ec 100%);
      font-size: $display-15;
      color: $white;
      font-weight: $font-weight-medium;
    }
    &:nth-child(odd) {
      border-right: 3px solid;
      padding-left: 0;
      &:before {
        left: 100%;
        margin-left: -20px;
      }
    }
    &:nth-child(even) {
      border-left: 3px solid;
      padding-right: 0;
      &:before {
        right: 100%;
        margin-right: -20px;
      }
    }
    &:first-child {
      border-top: 0;
      border-top-right-radius:0;
      border-top-left-radius:0;
    }
    &:last-child {
      border-bottom-right-radius:0;
      border-bottom-left-radius:0;
    }
  }
}

/*======================================
       TIMELINE AREA 2
 ======================================*/
.road-map {
  position: relative;
  z-index: 1;
  &:after {
    position: absolute;
    content: '';
    left: 50%;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: $theme-color;
    opacity: .2;
    @include transform(translateX(-50%));
    z-index: -1;
    @include border-radius(5px);
    @media #{$device-481} {
      display: none;
    }
  }
  .happening {
    &:hover {
      .happening-period {
        .happening__desc {
          background-color: $theme-color-2;
        }
        &:before {
          background-color: $theme-color-2;
          border-color: $white;
        }
      }
    }
    &:nth-child(2n+2) {
      .happening-period {
        float: right;
        text-align: left;
        padding-top: 60px;
        padding-right: 0;
        padding-left: 45px;
        @media #{$device-481} {
          padding-left: 0;
          float: none;
          text-align: right;
        }
        &:before {
          right: auto;
          left: 0;
          @include transform(translateX(-50%));
        }
      }
      .happening-detail {
        float: left;
        text-align: right;
        margin-top: 31px;
        margin-bottom: 31px;
        &:before {
          left: auto;
          right: -12px;
          @include transform(rotate(-179deg));
          @media #{$device-481} {
            top: -18px;
            right: 37px;
            @include transform(rotate(90deg));
          }
        }
      }
    }
    .happening-period {
      width: 50%;
      padding: 27px 42px 0 0;
      float: left;
      vertical-align: top;
      text-align: right;
      position: relative;
      @media #{$device-481} {
        width: 100%;
        float: none;
        text-align: left;
        padding-right: 0;
      }
      .happening__desc {
        font-size: $default-font-2;
        line-height: 16px;
        font-weight: $font-weight-medium;
        color: $white;
        display: inline-block;
        background-color: $theme-color;
        padding: 8px 15px;
        @include border-radius(30px);
        @include transition(0.3s);
      }
      &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        border: 3px solid $white;
        background-color: $theme-color;
        right: 0;
        @include transition(0.3s);
        @include transform(translateX(50%));
        @include border-radius(50%);
        @media #{$device-481} {
          display: none;
        }
      }
    }
    .happening-detail {
      width: 45%;
      float: right;
      padding: 27px 30px 23px 30px;
      position: relative;
      background-color: $white;
      @include border-radius(4px);
      @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
      @media #{$device-481} {
        width: 100%;
        float: none;
        text-align: left;
        margin-top: 30px;
      }
      .happening__title {
        font-size: $display-13;
        font-weight: $font-weight-semi-bold;
        margin-bottom: 15px;
      }
      .happening__desc {
        line-height: 28px;
      }
      &:before {
        position: absolute;
        top: 26px;
        left: -12px;
        content: '';
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right:12px solid $white;
        @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
        @media #{$device-481} {
          top: -18px;
          left: 37px;
          @include transform(rotate(90deg));
        }
      }
    }
    &:after {
      display: block;
      clear: both;
      content: "";
    }
    &:last-child {
      .happening-detail {
        @media #{$device-481} {
          margin-bottom: 0;
        }
      }
    }
  }
}
