/*-===============================
    INSTAGRAM WIDGET AREA
===============================-*/
.instagram-widget-area {
  .instagram-widget-wrap {
    margin-top: 30px;
    .col-lg-4.col-sm-6 {
      @media #{$large_mobile} {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}
/*-========== instagram-widget-item ============-*/
.instagram-widget-item {
  text-align: center;
  margin-bottom: 30px;
  @include border-radius(4px);
  @include transition(0.3s);
  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
  padding-bottom: 40px;
  .avatar-more-option {
    .dropdown {
      position: absolute;
      right: 20px;
      top: 80px;
    }
    .dropdown-toggle {
      border: none;
      @include border-radius(0);
      padding: 0;
      line-height: 6px;
      font-size: $display-9;
      background-color: transparent;
      color: rgba(158, 166, 186, 0.7);
      outline: none;
      .la-ellipsis-h {
        line-height: 6px;
      }
      &:after {
        display: none;
      }
    }
    .dropdown-menu {
      left: auto !important;
      right: 0;
      @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
      border-color: $color2;
      padding-top: 12px;
      padding-bottom: 12px;
      .dropdown-item {
        color: $theme-color;
        font-weight: $font-weight-medium;
        padding: 7px 25px;
        font-size: $default-font-3;
        &:hover {
          color: $theme-color-2;
        }
      }
    }
  }
  .instagram-widget-thumb {
    img {
      width: 100%;
      @include border-radius(4px 4px 0 0);
    }
  }
  .avatar-user-action {
    position: relative;
    margin-top: -55px;
    .avatar-user-profile {
      margin-bottom: 34px;
      img {
        width: 110px;
        height: 110px;
        @include border-radius(50%);
        border: 5px solid $white;
        @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
      }
      .avatar-user__name {
        font-size: $display-13;
        font-weight: $font-weight-semi-bold;
        margin-top: 18px;
        margin-bottom: 2px;
        .fa-check-circle {
          color: $alter-color-8;
        }
      }
      .avatar-user__meta {
        color: $theme-color-4;
        font-size: $default-font;
      }
      .avatar-user__text {
        line-height: 27px;
        margin-top: 10px;
        margin-bottom: 5px;
      }
      .avatar-user__link {
        color: $alter-color-8;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .instagram-widget-nav {
      margin-bottom: 34px;
      ul {
        li {
          display: inline-block;
          width: 29%;
          margin-right: 3%;
          span {
            display: block;
            &.instagram__number {
              color: $theme-color;
              font-size: $display-14;
              font-weight: $font-weight-semi-bold;
              margin-bottom: 3px;
            }
            &.instagram__text {
              font-weight: $font-weight-medium;
            }
          }
        }
      }
    }
    .instagram-widget-action-btn {
      .theme-btn {
        line-height: 40px;
        text-transform: capitalize;
        padding-right: 25px;
        padding-left: 25px;
        outline: none;
        &:hover {
          @include box-shadow(0 12px 24px -6px rgba(46, 61, 98, 0.1));
        }
      }
      .follow-btn {
        margin-right: 8px;
        background-color: $alter-color-8;
      }
      .message-btn {
        background-color: $white;
        border: 1px solid rgba(127,136, 151, 0.2);
        color: $theme-color;
        &:hover {
          border-color: rgba(127,136, 151, 0.6);
        }
      }
    }
  }
}
/*-========== instagram-widget-item-2 ============-*/
.instagram-widget-item-2 {
  padding-top: 40px;
  padding-right: 20px;
  padding-left: 20px;
  .instagram-widget-avatar {
    display: -ms-flex;
    display: flex;
    width: auto;
    height: auto;
    -ms-flex-align: center;
    align-items: center;
    @include border-radius(0);
    margin-bottom: 35px;
    @media #{$laptop_m_four} {
      display: block;
    }
    img {
      width: 80px;
      height: 80px;
      border: 3px solid $white;
      @include border-radius(50%);
      @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
      @media #{$laptop_m_four} {
       width: 90px;
        height: 90px;
      }
    }
    .instagram-widget-nav {
      margin-left: 18px;
      @media #{$laptop_m_four} {
        margin-left: 0;
        margin-top: 20px;
      }
      ul {
        li {
          display: inline-block;
          margin-right: 5px;
          @media #{$laptop_m_four} {
           margin-right: 10px;
          }
          span {
            display: block;
            &.instagram__number {
              color: $theme-color;
              font-size: $display-14;
              font-weight: $font-weight-semi-bold;
              margin-bottom: 3px;
            }
            &.instagram__text {
              font-weight: $font-weight-medium;
            }
          }
        }
      }
    }
    .instagram-widget-action-btn {
      margin-top: 8px;
      display: -ms-flex;
      display: flex;
      @media #{$laptop_m_four} {
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 10px;
      }
      .theme-btn {
        text-transform: capitalize;
        outline: none;
        line-height: 30px;
        padding-right: 15px;
        padding-left: 15px;
        @include border-radius(4px);
        margin-right: 5px;
        &:hover {
          @include transform(translateY(0));
          @include box-shadow(0 0 0 0);
        }
      }
      .follow-btn {
        background-color: $alter-color-8;
      }
      .message-btn {
        background-color: $white;
        border: 1px solid rgba(127,136, 151, 0.2);
        color: $theme-color;
        &:hover {
          border-color: rgba(127,136, 151, 0.6);
        }
      }
      .dropdown {
        position: relative;
        top: auto;
        right: auto;
      }
      .setting-btn {
        background-color: $white;
        border: 1px solid rgba(127,136, 151, 0.2);
        color: $theme-color;
        @include border-radius(4px);
        display: inline-block;
        font-size: $display-15;
        width: 32px;
        height: 32px;
        line-height: 32px;
        @include transition(0.3s);
        outline: none;
        &:after {
          display: none;
        }
        &:hover {
          border-color: rgba(127,136, 151, 0.6);
        }
      }
    }
  }
  .avatar-user-profile {
    text-align: left;
    @media #{$large_mobile} {
      text-align: center;
    }
    .avatar-user__name {
      font-size: $display-13;
      font-weight: $font-weight-semi-bold;
      margin-top: 18px;
      margin-bottom: 2px;
      .fa-check-circle {
        color: $alter-color-8;
      }
    }
    .avatar-user__meta {
      color: $theme-color-4;
      font-size: $default-font;
    }
    .avatar-user__text {
      line-height: 27px;
      margin-top: 10px;
      margin-bottom: 5px;
    }
    .avatar-user__link {
      color: $alter-color-8;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}