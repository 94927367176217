/*-===============================
    GALLERY AREA
===============================-*/
.gallery-area {
  .image-gallery-wrap {
    margin-top: 30px;
    figure {
      margin-bottom: 30px;
    }
  }
}

.image-gallery-item {
  .gallery__title {
    text-align: left;
    margin-bottom: 20px;
    font-weight: $font-weight-semi-bold;
    font-size: $display-12;
  }
  a {
    display: block;
    img {
      width: 100%;
      @include border-radius(4px);
    }
  }
}
/*-===============================
    GALLERY AREA 2
===============================-*/
.image-gallery-item-2 {
  .owl-nav {
    div {
      position: absolute;
      top: 41%;
      left: 10px;
      width: 45px;
      height: 45px;
      line-height: 45px;
      @include border-radius(50%);
      background-color: $white;
      color: $theme-color;
      @include transition(0.3s);
      &:hover {
        background-color: $theme-color-2;
        color: $white;
      }
      &.owl-next {
        left: auto;
        right: 10px;
      }
      @media #{$small_mobile_two} {
        top: 18%;
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
    }
  }
  .owl-dots {
    margin-top: 30px;
    .owl-dot {
      display: inline-block;
      margin-left: 5px;
      margin-right: 5px;
      @include border-radius(4px);
      @include transition(0.3s);
      position: relative;
      @media #{$laptop_m_four} {
        margin-bottom: 10px;
      }
      &.active,
      &:hover {
        &:after {
          opacity: 0;
        }
      }
      img {
        width: 115px;
        height: 100px;
        @include border-radius(4px);
        @media #{$laptop_m_four} {
          width: 113px;
        }
        @media #{$tab_device} {
          width: 110px;
        }
        @media #{$large_mobile} {
          width: 110px;
        }
        @media #{$large_mobile_two} {
          width: 101px;
          height: 90px;
        }
        @media #{$small_mobile} {
          width: 110px;
        }
      }
      &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $theme-color;
        opacity: 0.7;
        @include border-radius(4px);
        @include transition(0.3s);
      }
    }
  }
}

