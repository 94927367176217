/*-===============================
    GOOGLE
===============================-*/
.google-map {
  .map-wrap {
    margin-top: 50px;
    .map__heading {
      font-size: $display-13;
      font-weight: $font-weight-semi-bold;
      margin-bottom: 30px;
    }
  }
  iframe,
  #map{
    width: 100%;
    height: 450px;
  }
}

/*-===============================
    GOOGLE
===============================-*/
.google-map2 {
  .map-wrap {
    margin-top: 0;
  }
}





