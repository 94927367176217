/*-===============================
    PACKAGE AREA
===============================-*/
.package-area {
  position: relative;
  .section-heading {
    .section__title {
      br {
        @media #{$tab_device} {
          display: none;
        }
        @media #{$large_mobile} {
          display: none;
        }
        @media #{$small_mobile} {
          display: none;
        }
      }
    }
  }
  .package-content-wrap {
    margin-top: 55px;
    .col-lg-4 {
      @media #{$tab_device} {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}

/*========= package-item =========*/
.package-item {
  background-color: $white;
  @include box-shadow(0 0 40px rgba(14, 16, 48, 0.05));
  @include border-radius(4px);
  padding: 42px 40px 45px 40px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  @media #{$small_mobile} {
    width: auto;
  }
  .package-tooltip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @include border-radius(4px 4px 0 0);
    .package__tooltip {
      background-color: $theme-color-2;
      color: $white;
      font-weight: $font-weight-semi-bold;
      text-align: center;
      display: block;
      @include border-radius(4px 4px 0 0);
      padding: 15px 0;
      font-size: $display-13;
      position: relative;
      z-index: 1;
    }
  }
  .package-title {
    border-bottom: 1px solid rgba(127, 136, 151, 0.1);
    padding-bottom: 33px;
    margin-bottom: 35px;
    .package__price {
      font-size: $display-5;
      font-weight: $font-weight-semi-bold;
      margin-bottom: 10px;
      @media #{$small_mobile_three} {
        font-size: $display-6;
      }
    }
    .package__title {
      font-size: $display-11;
      font-weight: $font-weight-semi-bold;
    }
  }
  .package-list {
    padding-bottom: 33px;
    li {
      margin-bottom: 15px;
      span {
        color: $alter-color-4;
        font-size: $default-font-2;
        margin-right: 2px;
        &.la-close {
          color: $alter-color-5;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .package-price {
    .theme-btn {
      display: block;
      width: 100%;
      text-align: center;
      background-color: transparent;
      border: 1px solid $theme-color-2;
      color: $theme-color-2;
      &:hover {
        background-color: $theme-color-2;
        color: $white;
      }
    }
    .package__meta {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: $font-weight-medium;
      margin-top: 23px;
      font-size: $default-font-2;
      text-align: center;
    }
  }
}

/*========= package-item-active =========*/
.package-item-active {
  padding-top: 95px;
  margin-top: -24px;
  @media #{$tab_device} {
    margin-top: 0;
  }
  @media #{$large_mobile} {
    margin-top: 0;
  }
  @media #{$small_mobile} {
    margin-top: 0;
  }
  .package-price {
    .theme-btn {
      background-color: $theme-color-2;
      color: $white;
    }
  }
}
/*-===============================
    PACKAGE AREA 2
===============================-*/
.package-area2 {
  .package-content-wrap {
    margin-top: 30px;
  }
}
/*========= package-item-2 =========*/
.package-item-2 {
  @include border-radius(8px);
  border: 1px solid rgba(158, 166, 186, 0.2);
  @include box-shadow(0 0 0 0);
  .package-tooltip {
    width: auto;
    right: 20px;
    left: auto;
    top: 20px;
    .package__tooltip {
      display: inline-block;
      @include border-radius(8px);
      padding: 6px 16px;
      font-size: $default-font-2;
      background-color: $alter-color-7;
    }
  }
  .package-title {
    border-bottom: none;
    margin-bottom: 0;
    .package__price {
      color: $theme-color-2;
      margin-bottom: 0;
      small {
        font-size: $display-10;
        font-weight: $font-weight-semi-bold;
      }
    }
    .package__title {
      margin-bottom: 10px;
    }
  }
  .package-list {
    li {
      border-bottom: 1px solid rgba(158, 166, 186, 0.2);
      padding-bottom: 15px;
      span {
        color: $theme-color;
        float: right;
        margin-right: 0;
        font-weight: $font-weight-medium;
      }
      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
  .package-price {
    .theme-btn {
      display: block;
      width: 100%;
      text-align: center;
      background-color: $theme-color-2;
      border: none;
      color: $white;
      @include border-radius(8px);
      &:hover {
        background-color: $theme-color-3;
        color: $white;
      }
    }
    .package__meta {
      text-transform: inherit;
      letter-spacing: inherit;
    }
  }
}

/*========= package-item-active-2 =========*/
.package-item-active-2 {
  padding-top: 42px;
  margin-top: 0;
  .package-title {
    .package__price {
      color: $alter-color-7;
    }
  }
  .package-price {
    .theme-btn {
      background-color: $alter-color-7;
    }
  }
}

/*-===============================
    PACKAGE AREA 3
===============================-*/
.package-area3 {
  .package-content-wrap {
    margin-top: 30px;
    margin-right: 0;
    margin-left: 0;
    @media #{$tab_device} {
      margin-left: -15px;
      margin-right: -15px;
    }
    @media #{$large_mobile} {
      margin-left: -15px;
      margin-right: -15px;
    }
    @media #{$small_mobile} {
      margin-left: -15px;
      margin-right: -15px;
    }
    .col-lg-3 {
      padding: 0;
      margin: 0 -1px;
      @media #{$tab_device} {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: 0;
        margin-left: 0;
      }
      @media #{$large_mobile} {
       padding-right: 15px;
        padding-left: 15px;
        margin-right: 0;
        margin-left: 0;
      }
      @media #{$small_mobile} {
       padding-right: 15px;
        padding-left: 15px;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}
/*========= package-item-3 =========*/
.package-item-3 {
  @include border-radius(0);
  border: 1px solid rgba(127, 136, 151, 0.1);
  @include box-shadow(0 0 0 0);
  padding: 0;
  .package-title {
    padding-bottom: 37px;
    margin-bottom: 45px;
    .package__price {
      color: $theme-color-3;
      margin-bottom: 0;
      font-weight: $font-weight-regular;
    }
    .package__title {
      margin-bottom: 40px;
      border-bottom: 1px solid rgba(127, 136, 151, 0.1);
      padding: 30px;
      font-weight: $font-weight-medium;
    }
  }
  .package-price {
    padding-bottom: 35px;
    .package__btn {
      width: 55px;
      height: 55px;
      line-height: 55px;
      border: 2px solid rgba(127, 136, 151, 0.1);
      display: inline-block;
      @include border-radius(50%);
      color: rgba(127, 136, 151, 0.5);
      font-size: $display-14;
      @include transition(0.3s);
      &:hover {
        background-color: $theme-color-3;
        color: $white;
        border-color: $theme-color-3;
      }
    }
  }
}
/*========= package-item-active-2 =========*/
.package-item-active-3 {
  padding-top: 0;
  margin-top: 0;
  .package-title {
    .package__price {
      color: $theme-color-5;
    }
  }
  .package-price {
    .package__btn {
      background-color: $theme-color-5;
      color: $white;
      border-color: $theme-color-5;
    }
  }
}

/*-===============================
    PACKAGE AREA 4
===============================-*/
.package-area4 {
  .package-content-wrap {
    margin-top: 60px;
    margin-right: 0;
    margin-left: 0;
    @media #{$tab_device} {
     margin-right: -15px;
      margin-left: -15px;
    }
    @media #{$large_mobile} {
     margin-right: -15px;
      margin-left: -15px;
    }
    @media #{$small_mobile} {
     margin-right: -15px;
      margin-left: -15px;
    }
    .col-lg-4 {
      padding: 0;
      @media #{$tab_device} {
       padding-right: 15px;
        padding-left: 15px;
      }
      @media #{$large_mobile} {
       padding-right: 15px;
        padding-left: 15px;
      }
      @media #{$small_mobile} {
       padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
}
/*========= package--area4 =========*/
.package--area4 {
  .package-content-wrap {
    margin-top: 90px;
  }
}

/*========= package-item-4 =========*/
.package-item-4 {
  @include border-radius(4px);
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
  .package-title {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 90px;
    .package__price {
      color: $theme-color-2;
      margin-bottom: 0;
      background: #5c48e0;
      background: -moz-linear-gradient(-45deg,  #5c48e0 0%, #9c40e8 100%);
      background: -webkit-linear-gradient(-45deg,  #5c48e0 0%,#9c40e8 100%);
      background: linear-gradient(135deg,  #5c48e0 0%,#9c40e8 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .package__title {
      text-transform: uppercase;
      margin-bottom: 32px;
    }
    .package__month {
      color: $theme-color-2;
      font-size: $default-font;
      background: #5c48e0;
      background: -moz-linear-gradient(-45deg,  #5c48e0 0%, #9c40e8 100%);
      background: -webkit-linear-gradient(-45deg,  #5c48e0 0%,#9c40e8 100%);
      background: linear-gradient(135deg,  #5c48e0 0%,#9c40e8 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: $font-weight-regular;
    }
  }
  .package-list {
    li {
      font-weight: $font-weight-medium;
      &.text-disabled {
        color: rgba(127, 136, 151, 0.5);
        text-decoration: line-through;
      }
    }
  }
  .package-price {
    .theme-btn {
      background: #5c48e0;
      background: -moz-linear-gradient(-45deg,  #5c48e0 0%, #9c40e8 100%);
      background: -webkit-linear-gradient(-45deg,  #5c48e0 0%,#9c40e8 100%);
      background: linear-gradient(135deg,  #5c48e0 0%,#9c40e8 100%);
      border: none;
      color: $white;
      font-weight: $font-weight-semi-bold;
    }
  }
  &:after {
    position: absolute;
    content: '';
    width: 500px;
    height: 340px;
    top: -100px;
    left: -55px;
    background-color: $theme-color-6;
    @include border-radius(50%);
    z-index: -1;
    @media #{$tab_device} {
      left: -84px;
    }
    @media #{$large_mobile} {
      left: -45px;
      width: 600px;
    }
    @media #{$large_mobile_four} {
      left: -70px;
    }
    @media #{$small_mobile} {
      left: -100px;
      width: 600px;
    }
    @media #{$small_mobile_four} {
      left: -125px;
    }
    @media #{$small_mobile_three} {
      left: -155px;
    }
  }
}
/*========= package-item-active-4 =========*/
.package-item-active-4 {
  padding-top: 42px;
  margin-top: 0;
  @include transform(scale(1.1));
  z-index: 3;
  @media #{$tab_device} {
    @include transform(scale(1));
  }
  @media #{$large_mobile} {
    @include transform(scale(1));
  }
  @media #{$small_mobile} {
    @include transform(scale(1));
  }
  .package-title {
    .package__price,
    .package__month {
      background: #dddddd;
      background: -moz-linear-gradient(-45deg,  #dddddd 0%, #ffffff 100%);
      background: -webkit-linear-gradient(-45deg,  #dddddd 0%,#ffffff 100%);
      background: linear-gradient(135deg,  #dddddd 0%,#ffffff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .package__title {
      color: $white;
    }
  }
  &:after {
    background: #5c48e0;
    background: -moz-linear-gradient(-45deg,  #5c48e0 0%, #9c40e8 100%);
    background: -webkit-linear-gradient(-45deg,  #5c48e0 0%,#9c40e8 100%);
    background: linear-gradient(135deg,  #5c48e0 0%,#9c40e8 100%);
  }
}



/*-===============================
    PACKAGE AREA 5
===============================-*/
.package-area5 {
  .package-content-wrap {
    margin-top: 30px;
  }
}

/*========= package-tab-content =========*/
.package-tab-content {
  .nav-tabs-action {
    overflow: hidden;
    width: 242px;
    margin: 0 auto 30px auto;
    @include border-radius(4px);
    text-align: center;
    position: relative;
    z-index: 1;
    background: #f55974;
    background: -moz-linear-gradient(45deg,  #f55974 0%, #f8c652 100%);
    background: -webkit-linear-gradient(45deg,  #f55974 0%,#f8c652 100%);
    background: linear-gradient(45deg,  #f55974 0%,#f8c652 100%);
  }
  .nav-tabs {
    border-bottom: none;
    @include border-radius(4px);
    background-color: $white;
    margin: 1px;
    li {
      display: inline-block;
      a {
        display: block;
        padding: 12px 0;
        width: 120px;
        font-weight: $font-weight-semi-bold;
        background: #f55974;
        background: -moz-linear-gradient(45deg,  #f55974 0%, #f8c652 100%);
        background: -webkit-linear-gradient(45deg,  #f55974 0%,#f8c652 100%);
        background: linear-gradient(45deg,  #f55974 0%,#f8c652 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        &.active {
          background: #f55974;
          background: -moz-linear-gradient(45deg,  #f55974 0%, #f8c652 100%);
          background: -webkit-linear-gradient(45deg,  #f55974 0%,#f8c652 100%);
          background: linear-gradient(45deg,  #f55974 0%,#f8c652 100%);
          span {
            background: #efefef;
            background: -moz-linear-gradient(-45deg,  #efefef 0%, #ffffff 100%);
            background: -webkit-linear-gradient(-45deg,  #efefef 0%,#ffffff 100%);
            background: linear-gradient(135deg,  #efefef 0%,#ffffff 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }
  .tab-pane2 {
    .package-item {
      .package-title {
        .package__svg {
          svg {
            fill: url(#my-cool-gradient2) $alter-color-6;
          }
        }
      }
    }
  }
}

/*========= package-item-5 =========*/
.package-item-5 {
  text-align: center;
  padding: 0;
  .package-title {
    position: relative;
    border: none;
    padding: 42px 30px 30px 30px;
    margin-bottom: 65px;
    .package__price,
    .package__title{
      color: $white;
    }
    .package__title {
      margin-bottom: 10px;
    }
    .package__price {
      margin-bottom: 0;
      small {
        font-size: $display-11;
      }
    }
    .package__subtext {
      color: $white;
    }
    .package__svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 430px;
      z-index: -1;
      svg {
        width: 100%;
        height: 100%;
        fill: url(#my-cool-gradient) #f55974;
        stroke: none;
        @include border-radius(4px);
      }
    }
  }
  .package-list {
    padding-right: 30px;
    padding-left: 30px;
    li {
      font-weight: $font-weight-medium;
    }
  }
  .package-price {
    padding: 0 30px 30px 30px;
    .theme-btn {
      font-weight: $font-weight-semi-bold;
      background: #f55974;
      background: -moz-linear-gradient(45deg,  #f55974 0%, #f8c652 100%);
      background: -webkit-linear-gradient(45deg,  #f55974 0%,#f8c652 100%);
      background: linear-gradient(45deg,  #f55974 0%,#f8c652 100%);
      border: none;
      @include border-radius(4px);
      color: $white;
    }
    .package__meta {
      text-transform: inherit;
      letter-spacing: inherit;
      font-weight: $font-weight-regular;
    }
  }
}
/*========= package-item-active-5 =========*/
.package-item-active-5 {
  padding-top: 0;
  margin-top: 0;
}

/*-===============================
    PACKAGE AREA 6
===============================-*/
.package-area6 {
  .package-content-wrap {
    margin-top: 90px;
    .package-item {
      .package-title {
        .package__price,
        .package__month {
          -webkit-text-fill-color: $theme-color-2;
          background: transparent;
        }
      }
      .package-price {
        .theme-btn {
          background: $theme-color-2;
          &:hover {
            background: $theme-color-3;
          }
        }
      }
    }
    .package-item2 {
      .package-title {
        .package__price,
        .package__month {
          -webkit-text-fill-color: $white;
          background: transparent;
        }
      }
      &:after {
        background: $theme-color-2;
      }
    }
  }
}
/*-===============================
    PACKAGE AREA 7
===============================-*/
.package-area7 {
  .package-content-wrap {
    margin-top: 45px;
  }
}








