/*-===============================
    HIGHLIGHT AREA
===============================-*/
.highlight-area {
  .highlight-wrap {
    margin-top: 30px;
  }
}

.highlight-text {
  p {
    line-height: 28px;
    margin-bottom: 30px;
    mark {
      padding: 0;
    }
  }
}