/*-============ blockquote-item ===========-*/
.blockquote-item {
  margin-top: 30px;
  blockquote{
    padding: 15px 30px 15px 75px;
    border-left: 5px solid $alter-color-7;
    position: relative;
    margin-bottom: 0;
    @media #{$small_mobile_four} {
      padding-left: 30px;
    }
    .blockquote__icon{
      color: $alter-color-7;
      font-size: $display-9;
      position: absolute;
      left: 20px;
      top: 10px;
      @media #{$small_mobile_four} {
        position: inherit;
        top: auto;
        left: auto;
        margin-bottom: 20px;
      }
    }
    .blockquote__text {
      line-height: 30px;
      font-weight: $font-weight-medium;
      font-style: italic;
    }
    .blockquote__meta {
      font-weight: $font-weight-medium;
      margin-top: 16px;
      display: -ms-flex;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      @media #{$small_mobile_one} {
        display: block;
      }
      span {
        font-size: $default-font;
        color: $theme-color-4;
        margin-left: 5px;
      }
    }
  }
}

/*-============ blockquote-item-2 ===========-*/
.blockquote-item-2 {
  blockquote{
    border-left: none;
    background-image: url("../images/img11.jpg");
    background-size: cover;
    background-position: center;
    @include border-radius(4px);
    z-index: 1;
    padding-top: 40px;
    padding-bottom: 35px;
    padding-left: 40px;
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $theme-color;
      opacity: 0.8;
      z-index: -1;
      @include border-radius(4px);
    }
    .blockquote__icon{
      top: 20px;
      left: 50px;
      font-size: $display;
      color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: rgba(255, 255, 255, 0.1);
    }
    .blockquote__text,
    .blockquote__meta {
      color: $white;
    }
    .blockquote__meta {
      opacity: 0.8;
    }
  }
}

/*-============ blockquote-item-3 ===========-*/
.blockquote-item-3 {
  .blockquote {
    position: relative;
    margin-bottom: 0;
    h1 {
      position: relative;
      color: $theme-color-4;
      font-size: $display-14;
      line-height: 35px;
      margin: 0;
      border: 2px solid $color;
      @include border-radius(20px);
      padding: 25px 35px;
      &:after {
        content:"";
        position: absolute;
        border: 2px solid $color;
        @include border-radius(0 50px 0 0);
        width: 70px;
        height: 70px;
        bottom: -70px;
        left: 50px;
        border-bottom: none;
        border-left: none;
        z-index: 3;
      }
      &:before {
        content:"";
        position: absolute;
        width: 80px;
        border: 6px solid $white;
        bottom: -2px;
        left: 50px;
        z-index: 2;
      }
    }
    h4 {
      position: relative;
      padding-top: 15px;
      z-index: 1;
      margin-left:150px;
      padding-left:12px;
      span {
        font-size: $default-font;
        color: $theme-color-4;
        font-style: italic;
      }
    }
  }
}

/*-============ blockquote-item-4 ===========-*/
.blockquote-item-4 {
  .blockquote-item-text {
    line-height: 28px;
    margin-bottom: 15px;
  }
  blockquote{
    padding: 20px;
    border-left-color: $color;
  }
}

/*-============ blockquote-item-5 ===========-*/
.blockquote-item-5 {
  blockquote{
    border-left:none;
    padding: 45px 50px;
    @include border-radius(20px);
    background-color: $white;
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    position: relative;
    .info-dot {
      position: absolute;
      width: 15px;
      height: 15px;
      @include border-radius(50%);
      background-color: $color;
      &:nth-child(1) {
        top: 25px;
        left: 10%;
      }
      &:nth-child(2) {
        top: 60px;
        left: 50%;
      }
      &:nth-child(3) {
        bottom: 10%;
        left: 40%;
      }
      &:nth-child(4) {
        bottom: 20%;
        right: 20%;
      }
    }
    .quotation-mark{
      font-size: $display-4;
      margin-bottom: 20px;
      color: rgba(127, 138, 151, 0.2);
    }
    .quote-text{
      font-size: $display-14;
      line-height: 30px;
    }
    .blog-post-actions {
      border-top: 2px solid rgba(127, 138, 151, 0.1);
      display: -ms-flex;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin-top: 30px;
      padding-top: 30px;
      .blog-post-meta {
        font-weight: $font-weight-medium;
      }
      .blog-post-badge {
        display: -ms-flex;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        .quote-badge{
          background-color: rgba(127, 138, 151, 0.3);
          margin-right: 5px;
          padding: 8px 10px 5px 10px;
          font-size: 85%;
          line-height: 10px;
        }
      }
    }
    &:before,
    &:after {
      position: absolute;
      content: "";
      background-color: $white;
      @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
      left: 20px;
      top: 15px;
      margin-left: auto;
      margin-right: auto;
      z-index: -1;
      width: calc(100% - 40px);
      height: 100%;
      @include border-radius(20px);
    }
    &:after {
      left: 40px;
      top: 30px;
      z-index: -2;
      width: calc(100% - 80px);
    }
  }
}