/*-===============================
    ERROR
===============================-*/
.error-content {
  .error__img {
    margin-bottom: 40px;
    img {
      width: 100%;
    }
  }
  .error-heading {
    border-bottom: 1px solid rgba(127, 136, 151, 0.1);
    margin-bottom: 30px;
    padding-bottom: 30px;
    .error__title {
      font-weight: $font-weight-bold;
      font-size: $primary-font;
      margin-bottom: 10px;
      line-height: 60px;
      @media #{$small_mobile_one} {
        font-size: $display-9;
        line-height: 50px;
      }
    }
    .error__text {
      font-weight: $font-weight-medium;
      line-height: 28px;
      br {
        @media #{$tab_device} {
          display: none;
        }
        @media #{$large_mobile} {
          display: none;
        }
        @media #{$small_mobile} {
          display: none;
        }
      }
    }
  }
  .contact-form-action {
    .contact__text {
      margin-bottom: 20px;
    }
    form {
      .form-group {
        .form-control {
          padding-left: 20px;
        }
        .search-btn {
          background-color: $theme-color-2;
          border: none;
          color: $white;
          @include border-radius(0 4px 4px 0);
          text-transform: uppercase;
          @include transition(0.3s);
          &:hover {
            background-color: $theme-color-3;
          }
        }
      }
    }
  }
}