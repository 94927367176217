/*-===============================
    MOBILE AREA
===============================-*/
.mobile-app-area {
  .mobile-app-img {
    @media #{$tab_device} {
      margin-bottom: 60px;
    }
    @media #{$large_mobile} {
      margin-bottom: 60px;
    }
    @media #{$small_mobile} {
      margin-bottom: 60px;
    }
    img {
      width: 100%;
    }
  }
  .app-content {
    margin-left: 30px;
    @media #{$tab_device} {
      margin-left: 0;
    }
    @media #{$large_mobile} {
      margin-left: 0;
    }
    @media #{$small_mobile} {
      margin-left: 0;
    }
  }
}

.app-btn-box {
  @media #{$small_mobile_three} {
    text-align: center;
  }
  h4 {
    font-weight: $font-weight-semi-bold;
    margin-bottom: 20px;
  }
  .button {
    background-color: $white;
    color: $theme-color;
    border: 1px solid rgba(127, 136, 151, 0.2);
    span {
      background-color: $theme-color;
      color: $white;
    }
    &:hover {
      background-color: $theme-color-2;
      color: $white;
      border-color: $theme-color-2;
      span {
        background-color: $white;
        color: $theme-color;
      }
    }
    &:nth-child(2) {
      margin-right: 10px;
      @media #{$small_mobile_three} {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}
