/*-===============================
    banner-area
===============================-*/
.banner-area {
  position: relative;
  overflow: hidden;
  .banner-svg {
    position: absolute;
    top: 0;
    left: 0;
    fill: $theme-color-6;
    height: 112%;
  }
  .banner-item-wrap {
    position: relative;
    background-position: center center;
    background-size: cover;
    padding-top: 250px;
    padding-bottom: 120px;
    z-index: 2;
    @media #{$large_mobile} {
      padding-top: 200px;
      padding-bottom: 50px;
    }
    @media #{$small_mobile} {
      padding-top: 200px;
      padding-bottom: 50px;
    }
    .banner-item-table {
      display: table;
      width: 100%;
      height: 100%;
      .banner-item-tablecell {
        display: table-cell;
        vertical-align: middle;
      }
    }
    .banner-content {
      position: relative;
      z-index: 2;
      .section-heading {
        .section__meta {
          letter-spacing: 4px;
          color: $theme-color;
          background-color: rgba(46, 61, 98, 0.1);
          padding: 4px 14px;
          margin-bottom: 25px;
        }
        .section__title {
          font-size: $display-4;
          line-height: 80px;
          font-weight: $font-weight-bold;
          margin-bottom: 36px;
          @media #{$large_mobile} {
            font-size: $display-6;
            line-height: 60px;
          }
          @media #{$large_mobile_three} {
            font-size: $primary-font;
            line-height: 55px;
          }
          @media #{$small_mobile_one} {
            font-size: $display-7;
            line-height: 50px;
          }
          br {
            @media #{$small_mobile_four} {
              display: none;
            }
          }
        }
      }
      .section-description {
        .section__desc {
          font-size: $display-12;
          line-height: 37px;
          margin-bottom: 35px;
          @media #{$large_mobile_three} {
            font-size: $display-14;
          }
          br {
            @media #{$tab_device} {
             display: none;
            }
            @media #{$large_mobile} {
             display: none;
            }
            @media #{$small_mobile} {
             display: none;
            }
          }
        }
      }
      .btn-box {
        a {
          @media #{$small_mobile_one} {
            margin-right: 0;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}
/*-===============================
    HOME-PAGE 2
===============================-*/
.home-page-2 {
  .banner-item-wrap {
    padding: 0;
  }
  .single-slide-item {
    position: relative;
    background-position: center center;
    background-size: cover;
    color: $white;
    z-index: 2;
    padding-top: 290px;
    padding-bottom: 0;
    @media #{$tab_device} {
      padding-top: 230px;
    }
    @media #{$large_mobile} {
      padding-top: 230px;
    }
    @media #{$small_mobile} {
      padding-top: 230px;
    }
    .banner-number {
      position: absolute;
      left: 50%;
      bottom: 55px;
      @include transform(translateX(-50%));
      font-size: 100px;
      font-weight: $font-weight-semi-bold;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: rgba(255, 255, 255, 0.1);
    }
    .banner-content {
      .section-heading {
        .section__meta {
          color: $white;
          margin-bottom: 20px;
          -webkit-animation-duration: 2s;
          animation-duration: 2s;
          -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
          opacity: 0;
        }
        .section__title {
          color: $white;
          font-size: $display-5;
          line-height: 75px;
          margin-bottom: 25px;
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
          opacity: 0;
          @media #{$tab_device} {
            font-size: $display-6;
            line-height: 65px;
          }
          @media #{$large_mobile} {
            font-size: $display-6;
            line-height: 65px;
          }
          @media #{$small_mobile} {
            font-size: $display-6;
            line-height: 65px;
          }
          @media #{$small_mobile_three} {
            font-size: $display-7;
            line-height: 50px;
          }
          br {
            @media #{$tab_device} {
              display: none;
            }
            @media #{$large_mobile} {
              display: none;
            }
            @media #{$small_mobile} {
              display: none;
            }
          }
        }
      }
      .section-description {
        .section__desc {
          font-size: $display-14;
          line-height: 32px;
          margin-bottom: 40px;
          -webkit-animation-duration: 2s;
          animation-duration: 2s;
          -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
          opacity: 0;
        }
      }
      .btn-box {
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-animation-duration: 3s;
        animation-duration: 3s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        opacity: 0;
        .theme-btn {
          &:nth-child(1) {
            margin-right: 15px;
            @media #{$small_mobile} {
              margin-right: 0;
              margin-bottom: 15px;
            }
          }
          &:nth-child(2) {
            background-color: $white;
            color: $theme-color;
            &:hover {
              color: $white;
              background-color: $alter-color-7;
            }
          }
        }
      }
    }
    &:after {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: $theme-color;
      opacity: .90;
      content: "";
      z-index: -1;
    }
  }
  .single-slide-item1 {
    background-image: url(../images/slider-img.jpg);
    .banner-content {
      @media #{$small_mobile_three} {
       text-align: center;
      }
    }
  }
  .single-slide-item2 {
    background-image: url(../images/slider-img2.jpg);
    .banner-content {
      text-align: center;
      .btn-box {
        -ms-flex-pack: center;
        justify-content: center;
      }
    }
  }
  .single-slide-item3 {
    background-image: url(../images/slider-img3.jpg);
    .banner-content {
      text-align: right;
      @media #{$small_mobile_three} {
        text-align: center;
      }
      .btn-box {
        -ms-flex-pack: end;
        justify-content: flex-end;
        @media #{$small_mobile_three} {
          -ms-flex-pack: center;
          justify-content: center;
        }
      }
    }
  }
  .owl-nav {
    div {
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
      left: 20px;
      font-size: 26px;
      width: 50px;
      height: 50px;
      background-color: rgba(255, 255, 255, 0.1);
      line-height: 55px;
      text-align: center;
      color: rgba(255, 255, 255, 0.3);
      @include transition(0.3s);
      @include border-radius(4px);
      opacity: 0;
      visibility: hidden;
      &:hover {
        background-color: $white;
        color: $theme-color;
      }
      &.owl-next {
        left: auto;
        right: 0;
      }
    }
  }
  &:hover {
    .owl-nav {
      div {
        opacity: 1;
        visibility: visible;
        &.owl-prev {
          left: 20px;
        }
        &.owl-next {
          right: 20px;
        }
      }
    }
  }
}

.homepage-slide1 {
  .active {
    .section__meta,
    .section__title,
    .section__desc,
    .btn-box {
      -webkit-animation-name: fadeInDown;
      animation-name: fadeInDown;
      -webkit-animation-delay: .5s;
      animation-delay: .5s;
      opacity: 1;
    }
    .section__title {
      -webkit-animation-delay: .2s;
      animation-delay: .2s;
    }
    .section__desc {
      -webkit-animation-delay: .3s;
      animation-delay: .3s;
    }
    .btn-box {
      -webkit-animation-delay: .4s;
      animation-delay: .4s;
    }
  }
}

.banner-action-wrap {
  padding-top: 205px;
  padding-bottom: 30px;
  @media #{$large_mobile} {
    padding-top: 165px;
    text-align: center;
  }
  @media #{$small_mobile} {
    padding-top: 165px;
    text-align: center;
  }
  .banner-action {
    ul {
      li {
        display: inline-block;
        margin-right: 15px;
        color: $white;
        &:last-child {
          margin-right: 0;
        }
        a {
          color: $white;
          @include transition(0.3s);
          &:hover {
            opacity: 0.4;
          }
        }
      }
    }
  }
  .banner-action-right {
    text-align: right;
    @media #{$large_mobile} {
      text-align: center;
      margin-top: 10px;
    }
    @media #{$small_mobile} {
      text-align: center;
      margin-top: 10px;
    }
  }
}
/*-===============================
    home-page-3
===============================-*/
.home-page-3 {
  background: #e96443;
  background: -webkit-linear-gradient(to right, #904e95, #e96443);
  background: linear-gradient(to right, #904e95, #e96443);
  z-index: 1;
  .banner-item-wrap {
    .banner-content {
      @media #{$tab_device} {
        margin-bottom: 80px;
      }
      @media #{$large_mobile} {
        margin-bottom: 80px;
      }
      @media #{$small_mobile} {
        margin-bottom: 80px;
      }
      .section-heading {
        .section__meta {
          background-color: rgba(255, 255, 255, 0.1);
          color: $white;
          position: relative;
          padding-left: 35px;
          @media #{$small_mobile_four} {
            letter-spacing: 3px;
          }
          .section-dot {
            display: inline-block;
            margin-bottom: 0;
            margin-right: 3px;
            background-color: $theme-color-2;
            border-color: $white;
            position: absolute;
            top: 50%;
            left: 10px;
            @include transform(translateY(-50%));
          }
        }
        .section__title {
          color: $white;
          font-size: $display-5;
          line-height: 75px;
          @media #{$large_mobile_three} {
            font-size: $primary-font;
            line-height: 55px;
          }
          @media #{$small_mobile_one} {
            font-size: $display-7;
            line-height: 50px;
          }
        }
      }
      .btn-box {
        -ms-flex-pack: start;
        justify-content: flex-start;
        .theme-btn {
          background-color: $white;
          color: $theme-color;
          &:hover {
            background-color: $theme-color-2;
            color: $white;
          }
        }
        .video-play-btn {
          color: $white;
          .la-play {
            background-color: $theme-color-2;
            color: $white;
          }
          &:hover {
            color: $white;
          }
        }
      }
    }
    .banner-img-content {
      margin-top: -40px;
      z-index: 2;
      @media #{$tab_device} {
        margin-top: 0;
      }
      @media #{$large_mobile} {
        margin-top: 0;
      }
      @media #{$small_mobile} {
        margin-top: 0;
      }
      img {
        width: 100%;
      }
    }
  }
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/bg-line-shape.png");
    background-size: cover;
    background-position: center;
    z-index: -1;
    opacity: 0.4;
  }
  .text-outline {
    position: absolute;
    bottom: 85px;
    left: 50%;
    @include transform(translateX(-50%));
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.1);
    -ms-text-stroke-color: rgba(255, 255, 255, 0.1);
    -o-text-stroke-color: rgba(255, 255, 255, 0.1);
    color: transparent;
    font-size: 350px;
    font-weight: $font-weight-medium;
    line-height: 80px;
    letter-spacing: -0.01em;
    z-index: 1;
  }
}

/*-===============================
    home-page-4
===============================-*/
.home-page-4 {
  .banner-item-wrap {
    background-image: url("../images/banner-img.jpg");
    padding-top: 340px;
    padding-bottom: 200px;
    @media #{$large_mobile_four} {
      padding-top: 250px;
    }
    .line-bg {
      @include transform(rotate(0deg));
      &:before {
        display: none;
      }
    }
    .banner-content {
      .section-heading {
        .section-icon {
          &:before {
            color: rgba(255, 255, 255, 0.5);
            left: 0;
          }
          &:after {
            color: rgba(255, 255, 255, 0.4);
            right: 8px;
          }
        }
        .section__title {
          color: $white;
          margin-bottom: 20px;
        }
      }
      .section-description {
        .section__desc {
          color: $white;
          font-size: $display-14;
        }
      }
      .btn-box {
        -ms-flex-pack: start;
        justify-content: flex-start;
        @media #{$small_mobile_one} {
          width: 210px;
          text-align: left;
        }
        .contact-number {
          color: $white;
          margin-left: 20px;
          border-bottom: 1px solid $white;
          padding-bottom: 2px;
          @media #{$small_mobile_one} {
            margin-left: 0;
            margin-top: 20px;
            display: inline-block;
          }
          span {
            font-weight: $font-weight-medium;
          }
        }
      }
    }
    .preview-video {
      position: absolute;
      bottom: 60px;
      right: 80px;
      text-align: center;
    }
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $theme-color;
      opacity: 0.8;
      z-index: -1;
    }
  }
}
/*-===============================
    home-page-5
===============================-*/
.home-page-5 {
  .banner-item-wrap {
    padding-top: 0;
    padding-bottom: 0;
  }
  .slider-circle-shape {
    width: 600px;
    height: 600px;
    position: absolute;
    display: block;
    border: 100px dotted rgba(255, 255, 255, 0.5);
    opacity: 0.1;
    @include border-radius(50%);
    z-index: 1;
  }
  .slider-circle-shape1 {
    top: -200px;
    left: -230px;
    border: 100px dashed rgba(255, 255, 255, 0.5);
  }
  .slider-circle-shape2 {
    bottom: -210px;
    right: -210px;
  }
  .slider-circle-shape1,
  .slider-circle-shape2 {
    animation: spinme 11000ms infinite linear;
  }
  .single-slide-item {
    position: relative;
    background-position: center;
    background-size: cover;
    color: $white;
    z-index: 2;
    padding-top: 350px;
    overflow: hidden;
    @media #{$large_mobile} {
     padding-top: 250px;
    }
    @media #{$small_mobile} {
     padding-top: 250px;
    }
    .banner-content {
      .section-heading {
        .section__meta {
          font-weight: $font-weight-medium;
          margin-bottom: 20px;
          -webkit-animation-duration: 2s;
          animation-duration: 2s;
          -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
          opacity: 0;
          color: $white;
        }
        .section__title {
          color: $white;
          font-size: $display-5;
          line-height: 75px;
          margin-bottom: 25px;
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
          opacity: 0;
          @media #{$large_mobile_three} {
            font-size: $primary-font;
            line-height: 55px;
          }
          @media #{$small_mobile_one} {
            font-size: $display-7;
            line-height: 50px;
          }
          br {
            @media #{$tab_device} {
              display: none;
            }
            @media #{$large_mobile} {
              display: none;
            }
            @media #{$small_mobile} {
              display: none;
            }
          }
        }
      }
    }
    .section-description {
      .section__desc {
        margin-left: auto;
        font-size: $display-14;
        line-height: 32px;
        margin-bottom: 40px;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        opacity: 0;
      }
    }
    .btn-box {
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-animation-duration: 3s;
      animation-duration: 3s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      opacity: 0;
      .theme-btn {
        &:nth-child(1) {
          margin-right: 15px;
          @media #{$small_mobile_four} {
            margin-right: 0;
            margin-bottom: 15px;
          }
        }
        &:nth-child(2) {
          background-color: $white;
          color: $theme-color;
          &:hover {
            color: $white;
            background-color: $alter-color-2;
          }
        }
      }
    }
    &:after {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: $theme-color;
      opacity: .7;
      content: "";
    }
  }
  .single-slide-item2 {
    text-align: left !important;
    @media #{$small_mobile_three} {
      text-align: center !important;
    }
    .banner-content {
      .btn-box {
        -ms-flex-pack: start;
        justify-content: flex-start;
        @media #{$small_mobile_three} {
          -ms-flex-pack: center;
          justify-content: center;
        }
      }
    }
  }
  .slider-img-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @include transform(scale(1));
  }
  .slider-img-bg1 {
    background-image: url("../images/slider-img4.jpg");
  }
  .slider-img-bg2 {
    background-image: url("../images/slider-img5.jpg");
  }
  .slider-img-bg3 {
    background-image: url("../images/slider-img3.jpg");
  }
  .owl-nav {
    position: absolute;
    top: 50%;
    right: 20px;
    @include transform(translateY(-50%));
    @media #{$large_mobile_four} {
      display: none;
    }
    div {
      font-size: 26px;
      width: 50px;
      height: 50px;
      background-color: rgba(255, 255, 255, 0.1);
      @include border-radius(50%);
      line-height: 55px;
      color: rgba(255, 255, 255, 0.3);
      @include transition(0.3s);
      margin: 8px 0;
      &.owl-next,
      &:hover {
        background-color: $white;
        color: $theme-color;
      }
    }
  }
  .homepage-slide2 {
    .active {
      .single-slide-item {
        .slider-img-bg {
          @include transform(scale(1.16));
          -webkit-transition: all 7000ms linear;
          -moz-transition: all 7000ms linear;
          -ms-transition: all 7000ms linear;
          -o-transition: all 7000ms linear;
          transition: all 7000ms linear;
        }
      }
      .section__meta,
      .section__title,
      .section__desc,
      .btn-box {
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
        -webkit-animation-delay: .5s;
        animation-delay: .5s;
        opacity: 1;
      }
      .section__title {
        -webkit-animation-delay: .2s;
        animation-delay: .2s;
      }
      .section__desc {
        -webkit-animation-delay: .3s;
        animation-delay: .3s;
      }
      .btn-box {
        -webkit-animation-delay: .4s;
        animation-delay: .4s;
      }
    }
  }
}

.info-contact-box {
  margin-top: 200px;
  background: #7f8897;
  background: -webkit-linear-gradient(to right, #7f8897, #A3B0BA);
  background: linear-gradient(to right, #7f8897, #A3B0BA);
  text-align: left;
  position: relative;
  z-index: 1;
  @media #{$large_mobile} {
    margin-top: 140px;
  }
  @media #{$small_mobile} {
    margin-top: 140px;
  }
  .banner-contact-list {
    .banner-contact-item {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      padding: 50px 0;
      @media #{$tab_device} {
        padding-top: 30px;
        padding-bottom: 30px;
      }
      @media #{$large_mobile} {
        padding-top: 30px;
        padding-bottom: 30px;
        border-right: none;
      }
      @media #{$small_mobile} {
        padding-top: 30px;
        padding-bottom: 30px;
        border-right: none;
      }
      span {
        width: 60px;
        height: 60px;
        line-height: 60px;
        background-color: rgba(255, 255, 255, 0.1);
        @include border-radius(50%);
        font-size: $display-10;
        float: left;
        margin-right: 20px;
        text-align: center;
        position: relative;
        @include transition(0.3s);
        &:after {
          position: absolute;
          content: '';
          right: -6px;
          top: 50%;
          @include transform(translateY(-50%));
          width: 0;
          height: 0;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-left: 7px solid rgba(255, 255, 255, 0.1);
          @include transition(0.3s);
        }
      }
      .banner-contact__title {
        color: $white;
        font-size: $display-13;
        padding-top: 3px;
        padding-bottom: 3px;
      }
      .banner-contact__text {
        color: rgba(255, 255, 255, 0.7);
        a {
          color: rgba(255, 255, 255, 0.7);
        }
      }
      &:hover {
        span {
          background-color: $white;
          color: $theme-color;
          &:after {
            border-left-color: $white;
          }
        }
      }
    }
    .col-lg-4 {
      &:last-child {
        .banner-contact-item {
          border-right: none;
        }
      }
    }
  }
}
/*-===============================
    shop-home-page
===============================-*/
.shop-home-page {
  .single-slide-item {
    padding-top: 330px;
    padding-bottom: 202px;
    @media #{$tab_device} {
      padding-top: 250px;
    }
    @media #{$large_mobile} {
      padding-top: 250px;
    }
    @media #{$small_mobile} {
      padding-top: 250px;
    }
    .banner-number {
      -webkit-text-stroke-color: $white;
    }
    &:after {
      opacity: 0.4;
    }
  }
  .single-slide-item1 {
    background-image: url("../images/slider-img6.jpg");
  }
  .single-slide-item2 {
    background-image: url("../images/slider-img7.jpg");
  }
  .single-slide-item3 {
    background-image: url("../images/slider-img8.jpg");
  }
  .owl-nav {
    div {
      background-color: $white;
      color: $theme-color;
      @include border-radius(50%);
      &:hover {
        background-color: $theme-color-2;
        color: $white;
      }
    }
  }
}




