/*-===============================
    TEAM AREA
===============================-*/
.team-area {
  .team-content-wrap {
    margin-top: 30px;
    .col-lg-4 {
      @media #{$tab_device} {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}
/*-============ TEAM AREA ========-*/
.team-item {
  margin-bottom: 30px;
  background-color: $white;
  @include box-shadow(0 0 40px rgba(14,16,48,.05));
  border: 1px solid rgba(127, 136, 151, 0.1);
  @include border-radius(4px);
  position: relative;
  z-index: 1;
  @include transition(0.3s);
  .team-img-box {
    position: relative;
    img {
      width: 100%;
      @include border-radius(4px 4px 0 0);
      @include box-shadow(0 0 40px rgba(14,16,48,.05));
      border-bottom: 3px solid $theme-color-7;
    }
  }
  .team-titles {
    padding: 32px 40px 38px 40px;
    position: relative;
    z-index: 1;
    .team__title {
      font-size: $display-12;
      text-transform: capitalize;
      font-weight: $font-weight-semi-bold;
      display: inline-block;
      margin-bottom: 3px;
      a {
        color: $theme-color;
        @include transition(0.3s);
        display: block;
        position: relative;
        &:hover {
          color: $theme-color-2;
        }
      }
    }
    .team__meta {
      text-transform: capitalize;
      font-weight: $font-weight-medium;
      margin-bottom: 25px;
    }
    .team__desc {
      line-height: 28px;
      margin-bottom: 30px;
    }
    .theme-btn.team__btn {
      font-size: $default-font-3;
      padding-right: 35px;
      padding-left: 20px;
      line-height: 45px;
      .la {
        right: 15px;
      }
    }
    .team__profile {
      li {
        display: inline-block;
        padding-right: 20px;
        &:last-child {
          padding-right: 0;
        }
        a {
          color: $theme-color-4;
          display: block;
          @include transition(0.3s);
          &:hover {
            color: $theme-color-2;
          }
        }
      }
    }
  }
  &:hover {
    @include transform(translateY(-4px));
  }
}

/*-============ TEAM AREA 2 ========-*/
.team-item-2 {
  padding: 20px 20px 0 20px;
  text-align: center;
  @include border-radius(30px);
  .team-img-box {
    img {
      @include border-radius(30px);
      border-bottom: none;
    }
  }
  .team-titles {
    padding-right: 0;
    padding-left: 0;
    .team__profile {
      li {
        padding-right: 4px;
        a {
          display: block;
          width: 38px;
          height: 38px;
          line-height: 40px;
          background-color: rgba(46, 61, 98, 0.1);
          @include border-radius(50%);
          font-size: $display-14;
          &:hover {
            background-color: $theme-color;
            color: $white;
          }
        }
      }
    }
  }
}

/*-============ TEAM AREA 3 ========-*/
.team-item-3 {
  .team-img-box {
    img {
      border-bottom: none;
      @include box-shadow(0 0 0 0);
    }
    .img-svg-shape {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 140px;
      @include transform(translateX(-50%));
      fill: $white;
      stroke: none;
    }
    .team__profile {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      opacity: 0;
      visibility: hidden;
      @include transition(0.3s);
      li {
        padding-right: 2px;
        display: inline-block;
        a {
          display: block;
          width: 38px;
          height: 38px;
          line-height: 40px;
          text-align: center;
          background-color: $white;
          @include border-radius(50%);
          color: $theme-color;
          @include transition(0.3s);
          &:hover {
            background-color: $theme-color-2;
            color: $white;
          }
        }
      }
    }
  }
  &:hover {
    @include transform(translateY(0));
    .team-img-box {
      .team__profile {
        bottom: 30px;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}


/*-============ TEAM AREA 4 ========-*/
.team-item-4 {
  .team-img-box {
    img {
      @include border-radius(4px 4px 4px 30px);
      border-bottom: none;
    }
    .team__profile {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      @include transition(0.3s);
      background-color: rgba(46, 61, 98, 0.5);
      display: -ms-flex;
      display: -webkit-flex;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
      -ms-flex-pack: center;
      justify-content: center;
      @include border-radius(4px 4px 4px 30px);
      li {
        padding-right: 5px;
        display: inline-block;
        a {
          display: block;
          width: 38px;
          height: 38px;
          line-height: 40px;
          text-align: center;
          background-color: $white;
          @include border-radius(50%);
          color: $theme-color;
          @include transition(0.3s);
          &:hover {
            background-color: $theme-color-2;
            color: $white;
          }
        }
      }
    }
  }
  .team-titles {
    .team__meta {
      margin-bottom: 0;
    }
  }
  &:hover {
    .team-img-box {
      .team__profile {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

/*-============ TEAM AREA 5 ========-*/
.team-item-5 {
  @include border-radius(12px);
  background-color: transparent;
  border: none;
  .team-img-box {
    img {
      @include border-radius(12px);
      border-bottom: none;
    }
    .team-img-box-inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      @include transition(0.3s);
      background-color: rgba(46, 61, 98, 0.8);
      text-align: center;
      @include border-radius(12px);
      @include transform(scale(0.7));
      .team-inner-item {
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        width: 100%;
        .team__title {
          font-size: $display-12;
          text-transform: capitalize;
          font-weight: $font-weight-semi-bold;
          display: inline-block;
          margin-bottom: 3px;
          a {
            color: $white;
            @include transition(0.3s);
            display: block;
            position: relative;
            &:hover {
              color: $theme-color-2;
            }
          }
        }
        .team__meta {
          text-transform: capitalize;
          font-weight: $font-weight-medium;
          margin-bottom: 25px;
          color: rgba(255, 255, 255, 0.7);
        }
        .team__profile {
          li {
            padding-right: 20px;
            display: inline-block;
            a {
              display: block;
              color: $white;
              @include transition(0.3s);
              &:hover {
                color: $theme-color-2;
              }
            }
          }
        }
      }
    }
  }
  &:hover {
    @include transform(translateY(0));
    .team-img-box {
      .team-img-box-inner {
        @include transform(scale(1));
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

/*-===============================
    TEAM AREA 5
===============================-*/
.team-area6 {
  .owl-stage-outer {
    padding-top: 25px;
  }
  .owl-nav {
    div {
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      @include border-radius(50%);
      background-color: $white;
      border: 1px solid rgba(127, 136, 151, 0.1);
      @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
      font-size: $display-13;
      @include transition(0.3s);
      @media #{$laptop_m} {
        top: 38%;
        @include transform(translateY(0));
      }
      @media #{$large_mobile_three} {
        top: auto;
        bottom: -50px;
      }
      &:hover {
        background-color: $theme-color-2;
        color: $white;
      }
      &.owl-prev {
        left: -100px;
        @media #{$laptop_m} {
          left: -25px;
        }
        @media #{$large_mobile_three} {
          left: 200px;
        }
        @media #{$large_mobile_four} {
          left: 160px;
        }
        @media #{$small_mobile_one} {
          left: 130px;
        }
        @media #{$device-384} {
          left: 110px;
        }
        @media #{$small_mobile_three} {
          left: 85px;
        }
      }
      &.owl-next {
        left: auto;
        right: -100px;
        @media #{$laptop_m} {
          right: -25px;
        }
        @media #{$large_mobile_three} {
          right: 200px;
        }
        @media #{$large_mobile_four} {
          right: 160px;
        }
        @media #{$small_mobile_one} {
          right: 130px;
        }
        @media #{$device-384} {
          right: 110px;
        }
        @media #{$small_mobile_three} {
          right: 85px;
        }
      }
    }
  }
  .owl-dots {
    text-align: center;
    margin-top: 30px;
    div {
      width: 12px;
      height: 12px;
      background-color: $theme-color;
      @include border-radius(50%);
      display: inline-block;
      margin: 0 5px;
      opacity: 0.2;
      &.active {
        background-color: $theme-color-2;
        opacity: 1;
      }
    }
  }
}
/*-============ TEAM AREA 6 ========-*/
.team-item-6 {
  @include border-radius(50px);
  padding-top: 30px;
  border: none;
  text-align: center;
  .team-img-box {
    width: 280px;
    height: 280px;
    @include border-radius(50%);
    margin: 0 auto;
    @media #{$laptop_m_four} {
      width: 220px;
      height: 220px;
    }
    img {
      height: 100%;
      @include border-radius(50%);
      border: 10px solid $white;
      @include transition(0.3s);
    }
  }
  .team-titles {
    .team__meta {
      position: relative;
      padding-bottom: 26px;
      &:before,
      &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        @include border-radius(30px);
        width: 100%;
        height: 2px;
        background-color: rgba(158, 166, 186, 0.1);
      }
      &:after {
        background-color: $theme-color-2;
        width: 0;
        left: auto;
        right: 0;
        @include transition(0.3s);
      }
    }
  }
  &:hover {
    @include transform(translateY(0));
    .team-titles {
      .team__meta {
        &:after {
          width: 100%;
          right: auto;
          left: 0;
        }
      }
    }
  }
}

/*-============ TEAM AREA 7 ========-*/
.team-item-7 {
  border: none;
  .team-img-box {
    img {
      -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 92%, 0 100%, 0 0);
      clip-path: polygon(50% 0%, 100% 0, 100% 92%, 0 100%, 0 0);
      border-bottom: none;
    }
    .team__profile {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      @include transform(rotate(-3deg));
      @include transition(0.2s);
      opacity: 0;
      visibility: hidden;
      li {
        display: inline-block;
        opacity: 0;
        visibility: hidden;
        @include transform(translateY(60%));
        @include transition(1s cubic-bezier(0.23, 1, 0.32, 1));
        a {
          width: 35px;
          height: 35px;
          line-height: 35px;
          background-color: $white;
          color: $white;
          display: block;
          @include border-radius(50%);
          @include transition(0.3s);

        }
        &:nth-child(1) {
          -webkit-transition-delay: .1s;
          transition-delay: .1s;
          a {
            color: #3b5998;
          }
        }
        &:nth-child(2) {
          -webkit-transition-delay: .2s;
          transition-delay: .2s;
          a {
            color: #00acee;
          }
        }
        &:nth-child(3) {
          -webkit-transition-delay: .3s;
          transition-delay: .3s;
          a {
            color: #0e76a8;
          }
        }
        &:nth-child(4) {
          -webkit-transition-delay: .4s;
          transition-delay: .4s;
          a {
            color: #B832A4;
          }
        }
      }
    }
  }
  .team-titles {
    padding: 25px 35px 30px 35px;
    .team__title {
      font-size: $display-13;
    }
    .team__meta {
      margin-bottom: 0;
    }
    &:after {
      position: absolute;
      content: '';
      width: 3px;
      height: 35px;
      top: 30px;
      left: 0;
      background-color: $theme-color-2;
      @include border-radius(0 4px 4px 0);
    }
  }
  &:hover {
    .team-img-box {
      .team__profile {
        bottom: 30px;
        opacity: 1;
        visibility: visible;
        @media #{$large_mobile_three} {
          bottom: 45px;
        }
        li {
          opacity: 1;
          visibility: visible;
          @include transform(translateY(0));
        }
      }
    }
  }
}
/*-============ TEAM AREA 8 ========-*/
.team-item-8 {
  background-color: transparent;
  @include border-radius(0);
  @include box-shadow(0 0 0 0);
  .team-img-box {
    @include border-radius(50%);
    width: 260px;
    height: 260px;
    margin-left: auto;
    margin-right: auto;
    border: 10px solid $white;
    @media #{$laptop_m_four} {
      width: 210px;
      height: 210px;
    }
    img {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      @include border-radius(50%);
      width: 100%;
      height: 100%;
    }
    .team__profile {
      z-index: 1;
      bottom: 50%;
      @include transform(rotate(0) translateY(50%));
    }
    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      @include border-radius(50%);
      top: 0;
      left: 0;
      background-color: $theme-color;
      opacity: 0;
      visibility: hidden;
      @include transition(0.3s);
    }
  }
  .team-titles {
    padding: 25px 0 0 0;
    bottom: 50%;
    .section-divider {
      width: 40px;
      margin-top: 14px;
      margin-bottom: 14px;
      &:after {
        width: 5px;
        right: 10px;
      }
    }
    &:after {
      display: none;
    }
  }
  &:hover {
    .team-img-box {
      .team__profile {
        bottom: 50%;
      }
      &:after {
        opacity: 0.9;
        visibility: visible;
      }
    }
  }
}
/*===================================
    team-member-area
 ===================================*/
.team-member-area,
.team-member-area2 {
  .team-content-wrap {
    margin-top: 50px;
  }
}
/*===================================
    team-member-area 3
 ===================================*/
.team-member-area3 {
  .team-content-wrap {
    margin-top: 20px;
  }
}

/*===================================
    team-detail-area
 ===================================*/
.team-detail-area {
  .team-single-img {
    position: relative;
    border: 10px solid $white;
    @include border-radius(4px);
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    .team__img {
      width: 100%;
      @include border-radius(4px);
    }
    &:before {
      position: absolute;
      content: '';
      bottom: -40px;
      left: 50%;
      @include transform(translateX(-50%));
      width: 50%;
      height: 20%;
      border: 10px solid rgba(127, 136, 151, 0.1);
      @include border-radius(8px);
      z-index: -1;
    }
    &:after {
      position: absolute;
      content: '';
      height: 200px;
      width: 200px;
      @include border-radius(50%);
      left: -68px;
      top: -85px;
      background-image: url("../images/dots.png");
      background-size: cover;
      background-position: center;
      z-index: -1;
      animation: spinme 11000ms infinite linear;
      opacity: 0.2;
    }
  }
  .team-single-content {
    padding-left: 30px;
    @media #{$tab_device} {
      padding-left: 0;
      margin-top: 50px;
    }
    @media #{$large_mobile} {
      padding-left: 0;
      margin-top: 50px;
    }
    @media #{$small_mobile} {
      padding-left: 0;
      margin-top: 50px;
    }
    .author__title {
      font-size: $display-7;
      text-transform: capitalize;
      font-weight: $font-weight-semi-bold;
      margin-bottom: 8px;
      @media #{$small_mobile_one} {
        font-size: $display-9;
      }
    }
    .author__meta {
      display: block;
    }
    .author__meta2 {
      margin-top: 5px;
    }
    .author__contact {
      margin-top: 25px;
      li {
        display: block;
        position: relative;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          @include transition(0.3s);
          color: $theme-color-4;
          i {
            margin-right: 10px;
            font-size: $default-font-3;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            color: $theme-color;
            @include border-radius(50%);
            @include transition(0.3s);
            position: relative;
            background-color: rgba(46, 61, 98, 0.1);
          }
        }
        &:hover {
          a {
            color: $theme-color-2;
            i {
              background-color: $theme-color-2;
              color: $white;
            }
          }
        }
      }
    }
    .author-detail-box {
      margin-top: 34px;
      .author-detail-item {
        .authordetail__title {
          font-size: $display-14;
          font-weight: $font-weight-semi-bold;
          margin-bottom: 25px;
        }
        .authordetail__list {
          li {
            position: relative;
            margin-bottom: 10px;
            padding-left: 20px;
            &:after {
              position: absolute;
              content: '';
              top: 5px;
              left: 0;
              width: 10px;
              height: 10px;
              @include border-radius(50%);
              border: 2px solid $theme-color-2;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .education-detail {
        @media #{$tab_device} {
          margin-top: 30px;
        }
        @media #{$large_mobile} {
          margin-top: 30px;
        }
        @media #{$small_mobile} {
          margin-top: 30px;
        }
      }
    }
  }
  .about-item-wrap {
    .about__title {
      font-size: $display-12;
      font-weight: $font-weight-semi-bold;
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }
  .about-item {
    .about__desc {
      line-height: 30px;
    }
    .blockquote-item {
      margin-bottom: 25px;
    }
  }
  .publication-content {
    margin-top: 32px;
    border-top: 1px solid $color;
    padding-top: 34px;
    .pub__title {
      margin-bottom: 25px;
    }
    .publication-item {
      .pub__links {
        color: $theme-color;
        font-size: $display-15;
        display: block;
        font-weight: $font-weight-semi-bold;
        @include transition(0.3s);
        line-height: 23px;
        margin-bottom: 10px;
        @media #{$tab_device} {
          margin-bottom: 5px;
        }
        @media #{$large_mobile} {
          margin-bottom: 5px;
        }
        @media #{$small_mobile} {
          margin-bottom: 5px;
        }
        &:hover {
          color: $theme-color-2;
        }
      }
      .pub__meta {
        font-size: $default-font-3;
        i {
          color: $theme-color-2;
        }
      }
    }
    .publication-item2 {
      @media #{$tab_device} {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      @media #{$large_mobile} {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      @media #{$small_mobile} {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
  }
  .sidebar-content {
    margin-top: 55px;
    .help-desc-box {
      background-color: $theme-color;
      padding: 32px 33px 39px 33px;
      @include border-radius(4px);
      color: $white;
      margin-bottom: 40px;
      @media #{$tab_device} {
        margin-top: 60px;
      }
      @media #{$large_mobile} {
        margin-top: 60px;
      }
      @media #{$small_mobile} {
        margin-top: 60px;
      }
      .help__title {
        font-size: $display-12;
        font-weight: $font-weight-semi-bold;
        margin-bottom: 26px;
        color: $white;
      }
      .help__desc {
        font-weight: $font-weight-medium;
        margin-bottom: 40px;
      }
      .theme-btn {
        background-color: $white;
        color: $theme-color;
        &:hover {
          background-color: $theme-color-2;
          color: $white;
        }
      }
    }
    .present-btn {
      line-height: 70px;
      width: 100%;
      text-align: left;
    }
    .contact-form-action {
      margin-top: 40px;
      .contact-title {
        font-size: $display-12;
        font-weight: $font-weight-semi-bold;
        margin-bottom: 25px;
      }
      form {
        background-color: $white;
        @include border-radius(4px);
        @include box-shadow(0 0 40px rgba(82, 85, 90, 0.03));
        border: 1px solid rgba(127, 136, 151, 0.2);
        padding: 20px;
        position: relative;
        &:after {
          top: -6px;
          content: "";
          position: absolute;
          background-color: $white;
          left: 20px;
          width: 12px;
          height: 12px;
          @include transform(rotate(45deg));
          @include box-shadow(-2px -2px 10px -5px rgba(0,0,0,.2));
          border: 1px solid rgba(127, 136, 151, 0.2);
          border-right: 0;
          border-bottom: 0;
        }
      }
    }
  }
}



